import React, {
  useCallback,
  useRef,
  useEffect,
  FC,
  useState,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import ProjectCard from "../ProjectCard/index";
import map from "lodash/map";
import debounce from "lodash/debounce";
import {
  fetchFolders,
  setActiveFolder,
  addSelectedFolders,
  setSelectedFolders,
  setFolders,
  setFolderBreadcrumb,
  getUsersData,
  setProjects,
  fetchFiles,
  fetchProjects,
  setFoldersPageData,
  getStatusSummary,
  setSelectedProject,
  setFolderLoader,
  createHyperProject,
  fetchHyperProjects,
  setActiveFolderCreate,
  setProjectLoader,
  addFolder,
  setProjectsForReadyCheck,
  setUploadedBanners,
  getPSDJSON,
  setFileToProjectQueue,
  setUploadFileList,
  fileUploader,
  uploadToS3,
  projectUpload,
  updateProject,
  createProject,
  uploadFile,
} from "../../../store/actions";
import {
  FolderState,
  Folder,
  Banner,
  RolesState,
  HyperProjectFoldersState,
  AuthState,
} from "../../../store/types";
import "./index.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import {
  detectQueryString,
  drawerRenameTitle,
  getFileType,
  getUrlProperties,
} from "../../../utilities/common-function";
import { IMAGE_BASE_URL, S3_BASE_URL } from "../../../utilities/paths";
import { openToast } from "../../../Toasts";
import Icon, {
  ArrowLeftOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Tooltip } from "pyxis-ui-kit";
import { Popover } from "antd";
import {
  EmptyFile,
  EmptyFolder,
  DeleteAlert,
  TopFilter,
  DropDownOption,
  EditAlert,
  Masonry,
  UploadDesignsDrawer,
  ApprovalAlert,
} from "../../shared";
import { permissionDeniedClick } from "../../../utilities/common-function";
import PublicLinkDrawer from "../../Public/PublickLinkDrawer";
import { store } from "../../../store/store";
import { omit } from "lodash";
import uploadFileIcon from "../../../assets/svg/uploadFile.svg";
import { ReactComponent as art } from "../../../assets/svg/art.svg";
import { ReactComponent as AITools } from "../../../assets/svg/AITools.svg";

import { ReactComponent as uploadDesign } from "../../../assets/svg/uploadDesigns.svg";
import { ReactComponent as iconUpload } from "../../../assets/svg/iconUpload.svg";
import iconFolder from "../../../assets/svg/iconFolder.svg";
import { FolderAndFileUploadAlert } from "../FolderAndFileUploadAlert";
import async from "async";
import axios from "axios";
import { enableEdit } from "../../../utilities/contants";
import QuickViewAccess from "./QuickViewAccess";
import { v4 } from "uuid";
import { isEmpty } from "lodash";
import {
  getImage,
  getObject,
  getSvg1,
} from "../../Main/FabricLoaders/objectGetters";
import { customizedKeysArr } from "../../../ConversionContext/ConversionContext";
import { NODE_TYPE } from "../../Main/FabricLoaders/node-type";
import {
  incrementFileUploaded,
  trackUploadFiles,
} from "../../../utilities/services/mixpanel";
import ViewDetailsDrawer from "../../shared/DropDownOption/ViewDetailsDrawer";

interface contentInterface {
  type: string;
}

interface ContainerInterface {
  className: string;
}

interface FoldersInterface {
  folders: Folder[];
  type: string;
  canCreate: boolean;
  activeFolder: Folder;
  setOpenUploadDesignsPopUp?: Function;
  componentCaller: string;
  drawerVisible: string;
  setDrawerVisible: Function;
  isEditEnabled: boolean;
  setPublicLinkDrawerProps?: Function;
  setViewDetailsDrawerProps?: Function;
  isPublicLink?: boolean;
}

interface FolderInterface {
  folder: Folder;
  index: number;
  type: string;
  setDrawerVisible: Function;
  setCurrentProject: Function;
  folders: Folder[];
  changeActiveFolderInRouteForward: Function;
  canCreate: boolean;
  setPublicLinkDrawerProps?: Function;
  setViewDetailsDrawerProps?: Function;
}

interface BannerInterface {
  showTitle: boolean;
  title: string;
  type: string;
  canUpload: boolean;
  loading: boolean;
  projectsForReadyCheck: any;
  getActiveFolder: Function;
  componentCaller: string;
  drawerVisible: string;
  setDrawerVisible: Function;
  setPublicLinkDrawerProps?: Function;
  setViewDetailsDrawerProps?: Function;
  isEditEnabled: boolean;
  isPublicLink?: boolean;
}
interface UploadInterface {
  canUpload: boolean;
  type: string;
  setDrawerVisible: Function;
  isEditEnabled: boolean;
}

const getSrc = (image: any) => {
  if (!!image.blob) return image.blob;
  return image.getSrc();
};

const blobToFile = (theBlob: any, id: string) => {
  return new File([theBlob], `${id}-${Date.now()}.svg`, {
    type: "image/svg+xml",
    lastModified: Date.now(),
  });
};

export const base64ToFile = async (
  image: string,
  id: string
): Promise<File> => {
  const isSvg = image.includes("svg");
  const time = Date.now();
  const fileName = `${id}-${time}.${isSvg ? "svg" : "png"}`;

  if (isSvg) {
    const res = await fetch(image).then((r) => r.blob());
    return new File([res], fileName, { type: res.type, lastModified: time });
  }

  return (await fetch(image).then((r) => r.blob())) as File;
};

const getFile = (image: string | Blob, id: string) =>
  new Promise((res) => {
    const a =
      typeof image === "string"
        ? base64ToFile(image as string, id)
        : blobToFile(image as Blob, id);
    res(a);
  });

const FolderDisplay: FC<FolderInterface> = ({
  folder,
  setDrawerVisible,
  canCreate,
  index,
  type,
  setCurrentProject,
  folders,
  changeActiveFolderInRouteForward,
  setPublicLinkDrawerProps,
  setViewDetailsDrawerProps,
}) => {
  const state = useSelector((state: { folders: FolderState }) => state.folders);
  const {
    selectedFolders,
    uploadedFolder,
    folderBreadcrumb,
    folderContentCount,
    tempFolderContentCount,
  } = state;
  const [isOpenMoreVisible, setIsOpenMoreVisible] = useState(false);
  const [dropDownStep, setDropDownStep] = useState(0);
  const isSelected = selectedFolders.indexOf(folder.id) > -1;
  const clickEvents = useRef<any>([]);
  const dispatch = useDispatch();
  const folderIds = useMemo(
    () =>
      uploadedFolder
        ? uploadedFolder.reduce(
            (acc: number[], val: any) => [...acc, val.id],
            []
          )
        : [],
    [uploadedFolder]
  );

  const onSingleClick = useCallback(
    (id: number, index: number, shiftPressed: boolean) => {
      if (shiftPressed && selectedFolders.length > 0) {
        let fromIndex: number = folders.findIndex(
          (f: any) => f.id === selectedFolders[0]
        );
        let tempFolders =
          fromIndex < index
            ? folders.splice(fromIndex, index)
            : folders.splice(index, fromIndex);
        dispatch(
          addSelectedFolders(
            tempFolders.reduce((acc: any, val: any) => {
              if (selectedFolders.includes(val.id)) {
                return acc;
              } else {
                return [...acc, val.id];
              }
            }, [])
          )
        );
      } else {
        const callback = debounce(() => {
          if (selectedFolders.indexOf(id) > -1) {
            dispatch(
              setSelectedFolders(selectedFolders.filter((num) => num !== id))
            );
          } else {
            dispatch(addSelectedFolders([id]));
          }
          clickEvents.current = [];
        }, 200);
        clickEvents.current.push(callback);

        // We call the callback, which has been debounced (delayed)
        callback();
      }
    },
    [selectedFolders]
  );

  const onDoubleClick = useCallback(
    (folder: any) => {
      if (clickEvents.current.length > 0) {
        map(clickEvents.current, (debounce) => debounce.cancel());
        clickEvents.current = [];
      }
      dispatch(setFolderBreadcrumb([...folderBreadcrumb, folder]));
      changeActiveFolderInRouteForward(folder.id);
      dispatch(setSelectedFolders([]));
      dispatch(setActiveFolder(folder.id));
      dispatch(setFolderLoader(true));
      dispatch(
        fetchFolders(
          { type: type, parent: [folder.id] },
          (res: any, err: boolean) => {
            dispatch(setFolderLoader(false));
            if (!err) {
              dispatch(addFolder(res.results));
            }
          }
        )
      );
    },
    [clickEvents, type]
  );

  return (
    <div
      className={
        isSelected ? "folder flex active noselect" : "folder flex noselect"
      }
      key={folder.id}
      onClick={(e) => onSingleClick(folder.id, index, e.shiftKey)}
      onDoubleClick={() => {
        onDoubleClick(folder);
      }}
    >
      <div className='folder-info'>
        <div className='folder-name'>
          <img src={iconFolder} className='folder-icon' alt='' />
          <span className='name'>{folder.name}</span>
        </div>
        <div className='folder-counts'>
          <span className='folder-count'>
            {(folderIds.includes(folder.id)
              ? folderContentCount[folder.id]?.rootComplete
              : !isEmpty(tempFolderContentCount) &&
                tempFolderContentCount[folder.id] !== undefined
              ? tempFolderContentCount[folder.id]?.rootComplete
              : folder.content_count === undefined
              ? "0"
              : folder.content_count) +
              " Files, " +
              (folder.subfolder_count === undefined
                ? "0"
                : folder.subfolder_count) +
              " Folders"}
          </span>
        </div>
      </div>
      {
        <div
          className={
            isOpenMoreVisible || selectedFolders.length > 0
              ? selectedFolders.length > 0
                ? "overlay show overlay-no-borders"
                : "overlay show"
              : "overlay"
          }
        >
          {selectedFolders.length < 1 && (
            <DropDownOption
              componentCaller='folder'
              projectType={type}
              projectIndex={index}
              setPublicLinkDrawerProps={setPublicLinkDrawerProps}
              setViewDetailsDrawerProps={setViewDetailsDrawerProps}
              project={folder}
              isOpenMoreVisible={isOpenMoreVisible}
              setIsOpenMoreVisible={setIsOpenMoreVisible}
              setDrawerVisible={setDrawerVisible}
              setCurrentProject={setCurrentProject}
              setDropDownStep={setDropDownStep}
              dropDownStep={dropDownStep}
            />
          )}
          {selectedFolders.length > 0 && (
            <Checkbox
              checked={isSelected}
              onClick={(e) => onSingleClick(folder.id, index, e.shiftKey)}
            />
          )}
        </div>
      }
    </div>
  );
};

const Folders: React.FunctionComponent<FoldersInterface> = ({
  folders,
  type,
  canCreate,
  activeFolder,
  isPublicLink,
  componentCaller,
  drawerVisible,
  setDrawerVisible,
  setOpenUploadDesignsPopUp,
  isEditEnabled,
  setPublicLinkDrawerProps,
  setViewDetailsDrawerProps,
}) => {
  const state = useSelector((state: { folders: FolderState }) => state.folders);
  const {
    activeFolderId,
    banners,
    folderLoading,
    projectLoading,
    filter,
    folderBreadcrumb,
  } = state;
  const dispatch = useDispatch();
  const [currentProject, setCurrentProject] = useState<any>([]);
  const [open, setOpen] = useState<any>(false);
  const checkShowBreadcrumb: Function = useCallback(
    (folder: Folder) => (folder.id > 0 ? true : false),
    [activeFolder]
  );
  const publicLinkUser = useSelector(
    (state: { publicLink: any }) => state.publicLink
  );
  console.log("statefolders", state, publicLinkUser);
  let showBreadcrumb: boolean =
    activeFolder && checkShowBreadcrumb(activeFolder);
  const history = useHistory();
  const handleEmptyFolderClick = () => {
    if (canCreate) {
      setDrawerVisible("folder");
    } else {
      permissionDeniedClick();
    }
  };

  const levelUpClick = () => {
    if (activeFolder) {
      dispatch(setFolderBreadcrumb(folderBreadcrumb.slice(0, -1)));
      dispatch(setSelectedFolders([]));
      dispatch(setSelectedProject([]));
      dispatch(setActiveFolder(activeFolder.parent));
      dispatch(setFolderLoader(true));
      dispatch(
        fetchFolders(
          {
            type,
            parent: activeFolder.parent ? [activeFolder.parent] : undefined,
          },
          (res: any, err: boolean) => {
            dispatch(setFolderLoader(false));
            if (!err) {
              dispatch(addFolder(res.results));
            }
          }
        )
      );
      changeActiveFolderInRouteBack();
    }
  };

  const changeActiveFolderInRouteBack = useCallback(() => {
    const { baseUrl, search } = getUrlProperties();
    let newSearch = search.length > 0 ? search.split("-") : [""];
    newSearch.pop();
    let newurl =
      baseUrl + (newSearch.length > 0 ? "?" : "") + newSearch.join("-");
    window.history.pushState({ path: newurl }, "", newurl);
  }, []);

  const changeActiveFolderInRouteForward = useCallback((id: number) => {
    const { baseUrl, search } = getUrlProperties();
    let newurl =
      baseUrl +
      (search ? "?" + search : "") +
      (search.length === 0 ? "?folder=" + id : "-" + id);
    window.history.pushState({ path: newurl }, "", newurl);
  }, []);

  const changeActiveFolderByBreadcrumb = useCallback((folderId: number) => {
    const { baseUrl, search } = getUrlProperties();
    if (folderId !== 0) {
      // fetch all folder id from url
      let newSearch = search.length > 0 ? search.split("-") : [""];
      let newFolderPath: string = "";
      // loop on folder id from starting to current folder
      newSearch.some((id: string) => {
        newFolderPath = newFolderPath ? newFolderPath + "-" + id : id;
        if (
          (id.includes("folder=")
            ? parseInt(id.split("=").pop() || "")
            : parseInt(id)) === folderId
        ) {
          return true;
        }
      });
      let newurl = baseUrl + "?" + newFolderPath;
      window.history.pushState({ path: newurl }, "", newurl);
    } else {
      // Click on my folder
      window.history.pushState({ path: baseUrl }, "", baseUrl);
      dispatch(setActiveFolder(0));
      dispatch(setFolderBreadcrumb([]));
    }
  }, []);

  const breadcrumbClick = (folderId: number) => {
    const newFolder: Folder[] = [];
    folderBreadcrumb.some((folder: Folder) => {
      newFolder.push(folder);
      if (folder.id === folderId) {
        return true;
      }
    });
    dispatch(setFolderBreadcrumb(newFolder));
    dispatch(setSelectedFolders([]));
    dispatch(setSelectedProject([]));
    dispatch(setActiveFolder(folderId));
    dispatch(setFolderLoader(true));
    dispatch(
      fetchFolders({ type, parent: [folderId] }, (res: any, err: boolean) => {
        dispatch(setFolderLoader(false));
        if (!err) {
          dispatch(addFolder(res.results));
        }
      })
    );
    changeActiveFolderInRouteBack();
    // Change folderBreadCrumb status and url
    changeActiveFolderByBreadcrumb(folderId);
  };
  console.log("folderBreadcrumb", folderBreadcrumb);
  return (
    <>
      {(type === "file" || type === "hpproject" || type === "project"
        ? true
        : folders.length > 0) &&
        showBreadcrumb && (
          <div className='folder-breadcrumb'>
            {!isPublicLink && (
              <span className='breadcrumb'>
                <span
                  className='folder-name'
                  key={-1}
                  onClick={() => {
                    breadcrumbClick(0);
                  }}
                >
                  My Folder /
                </span>
                {folderBreadcrumb.map((folder: Folder, index: number) => {
                  return (
                    <span
                      className={
                        activeFolderId === folder.id
                          ? "folder-name active-folder"
                          : "folder-name"
                      }
                      key={index}
                      onClick={() => {
                        breadcrumbClick(folder.id);
                      }}
                    >
                      <span className='path'>{index !== 0 && " / "}</span>&nbsp;
                      <Tooltip
                        placement='top'
                        title={folder.name}
                        content={<span className='name'>{folder.name}</span>}
                      ></Tooltip>
                      &nbsp;
                    </span>
                  );
                  // return <breadCrumbItem className={activeFolderId===folder.id?"folder-name active-folder":"folder-name"} key={index} onClick={()=>{breadcrumbClick(folder.id)}}>{folder.name}&nbsp;</breadCrumbItem>
                })}
              </span>
            )}
            <div className='header'>
              <ArrowLeftOutlined
                className='back-arrow'
                onClick={levelUpClick}
              />
              <span className='current-folder'>{activeFolder.name}</span>
            </div>
          </div>
        )}
      {type === "project" &&
        !isPublicLink &&
        !(
          (folders.length > 0 &&
            banners.length === 0 &&
            !folderLoading &&
            !projectLoading &&
            filter &&
            filter["search"]) ||
          (folders.length === 0 &&
            banners.length === 0 &&
            !folderLoading &&
            !projectLoading)
        ) && (
          <ActionBar
            isEditEnabled={isEditEnabled}
            canCreate={canCreate}
            setOpenUploadDesignsPopUp={setOpenUploadDesignsPopUp}
          />
        )}
      <div className='folders'>
        {!folderLoading &&
        (type === "file" || type === "project" || type === "hpproject") &&
        folders.length === 0 ? (
          !isPublicLink && (
            <EmptyFolder
              title='Create a new folder'
              subtitle='Organize your files into folders. Create a custom folder for every new campaign.'
              btnTxt={
                <span
                  className={canCreate ? "flex" : "flex permission-denied-lock"}
                >
                  <span className='empty-folder-icon'>
                    <PlusCircleOutlined />
                  </span>
                  Create new folder
                </span>
              }
              handleClick={handleEmptyFolderClick}
            />
          )
        ) : (
          <>
            {(type === "file" || type === "hpproject" || type === "project"
              ? true
              : folders.length > 0) && (
              <TopFilter
                className='folder-filter'
                projectType={type}
                componentCaller='folder'
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                canManage={canCreate}
                openUploadVleProofs={true}
              />
            )}
            <div className='flexwrap folder-data'>
              {!isPublicLink && (
                <div
                  className='create-folder flex'
                  onClick={() => {
                    if (canCreate) {
                      setDrawerVisible("folder");
                    } else {
                      permissionDeniedClick();
                    }
                  }}
                >
                  <div
                    className={
                      canCreate ? "flex" : "flex permission-denied-lock"
                    }
                  >
                    <span className='mr-10'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z'
                          stroke='#7335CB'
                          stroke-width='1.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M12 11V17'
                          stroke='#7335CB'
                          stroke-width='1.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M9 14H15'
                          stroke='#7335CB'
                          stroke-width='1.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    </span>
                    <p className='add-folder'>New folder</p>
                  </div>
                </div>
              )}
              {folderLoading
                ? new Array(2)
                    .fill(null)
                    .map((f: any, i: number) => (
                      <div className='folder flex pulsate' key={i}></div>
                    ))
                : folders.map((folder: Folder, index: number) => (
                    <FolderDisplay
                      folder={folder}
                      index={index}
                      setPublicLinkDrawerProps={setPublicLinkDrawerProps}
                      setViewDetailsDrawerProps={setViewDetailsDrawerProps}
                      type={type}
                      setDrawerVisible={setDrawerVisible}
                      canCreate={canCreate}
                      setCurrentProject={setCurrentProject}
                      folders={folders}
                      changeActiveFolderInRouteForward={
                        changeActiveFolderInRouteForward
                      }
                    />
                  ))}
            </div>
          </>
        )}
        {drawerVisible === "folderApproval" && (
          <ApprovalAlert
            projectId={currentProject.id}
            isApproval={drawerVisible === "folderApproval"}
            componentCaller='folder'
            banners={banners}
            setDrawerVisible={setDrawerVisible}
            contentCount={currentProject.content_count}
            projectType={type}
          />
        )}
        {(drawerVisible === "folder" || drawerVisible === "uploadFile") && (
          <FolderAndFileUploadAlert
            isDrawerVisible={
              drawerVisible === "folder" || drawerVisible === "uploadFile"
            }
            type={type}
            changeActiveFolderInRouteForward={changeActiveFolderInRouteForward}
            projectType='folder'
            drawerVisibleFor={drawerVisible}
            setDrawerVisible={setDrawerVisible}
            isGlobalCreate={false}
          />
        )}
        {drawerVisible === "delete" && (
          <DeleteAlert
            title={`Delete selected folder?`}
            subTitle={`Are you sure you want to delete selected folder?`}
            isDeleteDrawerVisible={drawerVisible === "delete"}
            componentCaller={componentCaller}
            projectType={type}
            currentProject={currentProject}
            setDrawerVisible={setDrawerVisible}
            setCurrentProject={setCurrentProject}
          />
        )}
        {drawerVisible === "folderRename" && (
          <EditAlert
            title='Rename Folder'
            subTitle='Enter a new folder name for easy discovery'
            label='folder name'
            isRenameDrawerVisible={drawerVisible === "folderRename"}
            successTitle='Great! Folder renamed successfully'
            successSubTitle=''
            currentProject={currentProject}
            setDrawerVisible={setDrawerVisible}
            drawerOpenFor={drawerVisible}
          />
        )}
      </div>
    </>
  );
};

const Banners: React.FunctionComponent<BannerInterface> = ({
  title,
  showTitle,
  type,
  isEditEnabled,
  canUpload,
  loading,
  projectsForReadyCheck,
  getActiveFolder,
  componentCaller,
  drawerVisible,
  setDrawerVisible,
  setPublicLinkDrawerProps,
  setViewDetailsDrawerProps,
  isPublicLink,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state: { folders: FolderState }) => state.folders);
  const { activeFolderId, banners, pageData, filter, selectedProjects } = state;
  const [currentProject, setCurrentProject] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const titleRef = useRef(null);
  const isSearched = useMemo(() => filter && filter["search"], [filter]);
  const [hasFailedProjects, setHasFailedProjects] = useState(false);
  const [isMultipleDownloadDisable, setIsMultipleDownloadDisable] = useState(
    false
  );
  const [isBothFormatSelected, setIsBothFormatSelected] = useState(false);
  let renameTitle = drawerRenameTitle(drawerVisible, type);

  useEffect(() => {
    let hasFailProject: boolean = false;
    let isMultipleDownloadDisabled: boolean = false;
    let isBothFormatSelected: boolean = false;
    selectedProjects &&
      selectedProjects.map((id: number) => {
        if (type === "hpproject" || type === "project") {
          const selectedProject = banners.find((p: any) => p.id === id);
          if (type === "hpproject") {
            if (selectedProject?.ready_status === "FAILED") {
              hasFailProject = true;
            }
          } else if (type === "project") {
            if (selectedProject?.render_status === "FAILED") {
              hasFailProject = true;
            }
            if (
              selectedProject?.type === "image" ||
              selectedProject?.type === "image_set" ||
              selectedProject?.type === "variant_set"
            ) {
              if (
                selectedProject?.file_type === "bmp" ||
                selectedProject?.file_type === "ico"
              ) {
                isMultipleDownloadDisabled = true;
              } else {
                isBothFormatSelected = true;
              }
            } else {
              isMultipleDownloadDisabled = true;
            }
          }
        }
      });
    setHasFailedProjects(hasFailProject);
    setIsMultipleDownloadDisable(isMultipleDownloadDisabled);
    setIsBothFormatSelected(isBothFormatSelected);
  }, [selectedProjects?.length]);

  const scrollToTop = () => {
    let node: any = titleRef?.current;
    if (node) {
      node.scrollIntoView({ behavior: "smooth" });
    }
  };

  const createHProject = (data: any, setLoader?: Function) => {
    setLoader && setLoader(true);
    dispatch(
      createHyperProject(
        { project_id: data.id, title: data.title, description: "" },
        (res: any, err: boolean) => {
          setLoader && setLoader(false);
          let { data } = res;
          if (!err) {
            if (openModal) setOpenModal(false);
            let hyperProject = {
              id: data.id,
              parent: activeFolderId ? activeFolderId : 0,
              title: data.title,
              type: data.type,
              can_manage: data.can_manage || true,
              created_by: data.created_by,
              created_at: data.created_at,
              render_status: data.render_status,
              ready_status: data.ready_status,
              ready_failure_reason: data.ready_failure_reason,
              ready_progress: data.ready_progress,
              rendered_file:
                IMAGE_BASE_URL +
                data.rendered_file +
                (detectQueryString(data.rendered_file) ? "&q=40" : "?q=40"),
              thumbnail: data.thumbUrl,
            };
            if (type === "hpproject") {
              dispatch(setProjects([hyperProject, ...banners]));
            } else {
              res.data = {
                ...res.data,
                rendered_file: IMAGE_BASE_URL + data.rendered_file,
              };
              dispatch(
                setProjectsForReadyCheck([...projectsForReadyCheck, res.data])
              );
            }
            openToast("success", "Creative automation project created!");
          } else {
            openToast(
              "info",
              data?.message || "Please add a non empty project"
            );
          }
        }
      )
    );
  };

  return (
    <div className={`banners ${banners.length === 0 ? " noBanner" : ""}`}>
      {banners.length > 0 && showTitle && (
        <TopFilter
          className={isSearched ? "title-searched heading" : "title heading"}
          componentCaller={componentCaller}
          projectType={type}
          isSearched={isSearched}
          title={
            isSearched
              ? `Search Results for '${filter["search"].slice(0, 100)}${
                  filter["search"].length > 100 ? "..." : ""
                }' in ${
                  activeFolderId === 0
                    ? "My Folder"
                    : getActiveFolder(activeFolderId, type)?.name
                }`
              : title
          }
          titleRef={titleRef}
          getActiveFolder={getActiveFolder}
          drawerVisible={drawerVisible}
          setDrawerVisible={setDrawerVisible}
          canManage={canUpload}
          hasFailedProjects={hasFailedProjects}
          isMultipleDownloadDisable={isMultipleDownloadDisable}
          isBothFormatSelected={isBothFormatSelected}
        />
      )}
      <div
        className='banner-data'
        style={banners.length > 0 ? { marginBottom: "5rem" } : {}}
      >
        <InfiniteScroll
          dataLength={banners.length}
          next={() => {
            if (pageData && type === "file") {
              dispatch(setProjectLoader(true));
              dispatch(
                fetchFiles(
                  {
                    page: pageData.current_page + 1,
                    folder: activeFolderId,
                    page_size: 20,
                  },
                  (res: any, err: boolean) => {
                    dispatch(setProjectLoader(false));
                    if (!err) {
                      dispatch(
                        setFoldersPageData({
                          count: res.count,
                          current_page: res.current_page,
                          page_size: res.page_size,
                          pages: res.pages,
                        })
                      );
                      dispatch(
                        setProjects(
                          res.current_page > 1
                            ? [...banners, ...res.results]
                            : res.results
                        )
                      );
                    }
                  }
                )
              );
            }
            if (pageData && type === "project") {
              dispatch(setProjectLoader(true));
              // First 20 banners are loaded from leftSideBar
              dispatch(
                fetchProjects(
                  {
                    page: pageData.current_page + 1,
                    folder_id: activeFolderId,
                    order_by: "-created_at",
                  },
                  (res: any, error: boolean) => {
                    if (!error) {
                      dispatch(
                        setFoldersPageData({
                          count: res.count,
                          current_page: res.current_page,
                          page_size: res.page_size,
                          pages: res.pages,
                        })
                      );
                      res = res.data;
                      let ids = res.reduce(
                        (acc: number[], val: any) => [...acc, val.id],
                        []
                      );
                      let userIds = res.reduce((acc: any, value: any) => {
                        if (!acc.includes(value.created_by)) {
                          acc.push(value.created_by);
                        }
                        return acc;
                      }, []);
                      if (userIds.length > 0) {
                        dispatch(
                          getUsersData(
                            { userIds: userIds },
                            (userres: any, usererr: boolean) => {
                              if (!usererr) {
                                let { users } = userres.data;
                                res.forEach((project: any, index: number) => {
                                  project.created_by =
                                    users.find(
                                      (user: any) =>
                                        user._id === project.created_by
                                    ) || "";
                                });
                              }
                            }
                          )
                        );
                      }
                      dispatch(
                        getStatusSummary(
                          { ids, entity_type: type },
                          (summaryRes: any, summaryErr: boolean) => {
                            dispatch(setProjectLoader(false));
                            if (!summaryErr) {
                              let {
                                data: { results },
                              } = summaryRes;
                              results.forEach((d: any) => {
                                let found: any = res.findIndex(
                                  (obj: any) =>
                                    obj.id.toString() === d.entity_id
                                );
                                if (found !== -1) {
                                  res[found]["status"] = d.status;
                                  res[found]["entity_id"] = d.entity_id;
                                }
                              });
                            }
                            if (pageData.current_page + 1 > 1) {
                              dispatch(setProjects([...banners, ...res]));
                            } else {
                              dispatch(setProjects(res));
                            }
                          }
                        )
                      );
                    } else {
                      dispatch(setProjectLoader(false));
                    }
                  }
                )
              );
            }
            if (pageData && type === "hpproject") {
              dispatch(setProjectLoader(true));
              // First 20 banners are loaded from leftSideBar
              dispatch(
                fetchHyperProjects(
                  {
                    page: pageData.current_page + 1,
                    folder: activeFolderId,
                    page_size: 20,
                  },
                  (res: any, err: boolean) => {
                    if (!err) {
                      let ids = res.reduce(
                        (acc: number[], val: any) => [...acc, val.id],
                        []
                      );
                      let userIds = res.reduce(
                        (acc: number[], val: any) => [...acc, val.created_by],
                        []
                      );
                      if (userIds.length > 0) {
                        dispatch(
                          getUsersData(
                            { userIds: userIds },
                            (userres: any, usererr: boolean) => {
                              if (!usererr) {
                                let { users } = userres.data;
                                res.forEach((project: any, index: number) => {
                                  project.created_by =
                                    users.find(
                                      (user: any) =>
                                        user._id === project.created_by
                                    ) || "";
                                });
                              }
                            }
                          )
                        );
                      }
                      dispatch(
                        getStatusSummary(
                          { ids, entity_type: type },
                          (summaryRes: any, summaryErr: boolean) => {
                            dispatch(setProjectLoader(false));
                            if (!summaryErr) {
                              let {
                                data: { results },
                              } = summaryRes;
                              results.forEach((d: any) => {
                                let found: any = res.findIndex(
                                  (obj: any) =>
                                    obj.id.toString() === d.entity_id
                                );
                                if (found !== -1) {
                                  res[found]["status"] = d.status;
                                  res[found]["entity_id"] = d.entity_id;
                                }
                              });
                            }
                            if (pageData.current_page + 1 > 1) {
                              dispatch(setProjects([...banners, ...res]));
                            } else {
                              dispatch(setProjects(res));
                            }
                          }
                        )
                      );
                    } else {
                      dispatch(setProjectLoader(false));
                    }
                  }
                )
              );
            }
          }}
          hasMore={(pageData?.current_page || 0) < (pageData?.pages || 0)}
          loader={null}
          className='columns'
          scrollableTarget='content'
          style={{ overflow: "none" }}
        >
          <Masonry
            breakpointCols={{
              default: 6,
              2100: 5,
              1700: 5,
              1400: 5,
              1150: 5,
              800: 3,
            }}
            className='masonry'
            columnClassName='column'
            loader={
              loading ? (
                <div className='project-card'>
                  <div className='card'>
                    <div className='card-body pulsate'></div>
                  </div>
                </div>
              ) : null
            }
            showinfirst={
              banners.length > 0 &&
              !isPublicLink &&
              (type === "file" || type === "hpproject") ? (
                <Upload
                  isEditEnabled={isEditEnabled}
                  setDrawerVisible={setDrawerVisible}
                  canUpload={canUpload}
                  type={type}
                />
              ) : (
                undefined
              )
            }
          >
            {banners.map((banner: Banner, index: number) => (
              <ProjectCard
                componentCaller={componentCaller}
                isEditEnabled={isEditEnabled}
                data={banner}
                type={type}
                key={banner.id}
                createHyperProject={createHProject}
                scrollToTop={scrollToTop}
                projectIndex={index}
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                setPublicLinkDrawerProps={setPublicLinkDrawerProps}
                setViewDetailsDrawerProps={setViewDetailsDrawerProps}
                setCurrentProject={setCurrentProject}
              />
            ))}
          </Masonry>
        </InfiniteScroll>
      </div>
      {(drawerVisible === "rename" ||
        drawerVisible === "addToTemplate" ||
        drawerVisible === "creativeAutomation") && (
        <EditAlert
          title={
            (drawerVisible === "rename" ? "Rename " : "") +
            renameTitle +
            (drawerVisible !== "rename" ? " name" : "")
          }
          subTitle={
            "Enter a new " +
            renameTitle +
            " name" +
            (drawerVisible === "rename" ? " for easy discovery" : "")
          }
          label={renameTitle + " name"}
          isRenameDrawerVisible={
            drawerVisible === "rename" ||
            drawerVisible === "addToTemplate" ||
            drawerVisible === "creativeAutomation"
          }
          successTitle={
            "Great! " +
            renameTitle +
            (drawerVisible === "rename" ? " renamed" : " added") +
            " successfully"
          }
          successSubTitle=''
          projectType={type}
          currentProject={currentProject}
          setDrawerVisible={setDrawerVisible}
          drawerOpenFor={drawerVisible}
          createHyperProject={createHProject}
        />
      )}
      {drawerVisible === "approval" && (
        <ApprovalAlert
          projectId={currentProject.id}
          isApproval={drawerVisible === "approval"}
          projectType={type}
          banners={banners}
          componentCaller='project'
          setDrawerVisible={setDrawerVisible}
        />
      )}
    </div>
  );
};

const Upload: React.FunctionComponent<UploadInterface> = ({
  canUpload,
  type,
  setDrawerVisible,
  isEditEnabled,
}) => {
  const history = useHistory();

  return (
    <div
      className='add-card'
      onClick={() => {
        if (canUpload) {
          if (type === "file") {
            setDrawerVisible("uploadFile");
          } else if (type === "hpproject" && isEditEnabled) {
            history.push("/cocreate/creative-automation-choose-design");
          }
        } else {
          permissionDeniedClick();
        }
      }}
    >
      {type === "hpproject" ? (
        <Tooltip
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          placement='bottom'
          title={
            !isEditEnabled
              ? "Get in touch with our customer success manager to know more about our editing functionality."
              : ""
          }
          content={
            <span>
              <span className='icon'>
                <PlusOutlined
                  className={
                    canUpload && isEditEnabled
                      ? "add-icon"
                      : "add-icon add-icon-disabled"
                  }
                />
              </span>
              <span
                className={
                  canUpload && isEditEnabled
                    ? "upload-title noselect"
                    : "upload-title noselect permission-denied-lock"
                }
              >
                Add file from designs
              </span>
            </span>
          }
        ></Tooltip>
      ) : (
        <>
          <span className='icon'>
            <img src={uploadFileIcon} className='upload-icon' alt='' />
          </span>
          <span
            className={
              canUpload ? "upload-title" : "upload-title permission-denied-lock"
            }
          >
            Upload files
          </span>
        </>
      )}
    </div>
  );
};

const ActionBar: React.FC<{
  canCreate: boolean;
  setOpenUploadDesignsPopUp: Function;
  isEditEnabled: boolean;
}> = ({ canCreate, setOpenUploadDesignsPopUp, isEditEnabled }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className='create-design'>
      {isEditEnabled && (
        <Tooltip
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          placement='bottom'
          title={
            !isEditEnabled
              ? "Get in touch with our customer success manager to know more about our editing functionality."
              : ""
          }
          content={
            <span>
              <Button
                className={isEditEnabled ? "primary" : "primary btn-disabled"}
                type='primary'
                disabled={!isEditEnabled}
                style={{ width: "140px" }}
                btnsize='md'
                onClick={() => {
                  if (canCreate && isEditEnabled) {
                    history.push("/cocreate/create");
                  } else {
                    permissionDeniedClick();
                  }
                }}
              >
                <span
                  className={
                    canCreate && isEditEnabled
                      ? "flex"
                      : "flex permission-denied-lock"
                  }
                >
                  <Icon component={AITools} className='icon' />
                  <div className='title'>AI tools</div>
                </span>
              </Button>
            </span>
          }
        ></Tooltip>
      )}
      <Button
        type='default'
        className='upload'
        onClick={() => {
          if (canCreate) {
            setOpenUploadDesignsPopUp(true);
            dispatch(setUploadedBanners([]));
            dispatch(setUploadFileList([]));
          } else {
            permissionDeniedClick();
          }
        }}
      >
        <span className={canCreate ? "flex" : "flex permission-denied-lock"}>
          <Icon component={uploadDesign} className='icon' />
          <div className='title'>Upload Design</div>
        </span>
      </Button>
    </div>
  );
};

const Content: React.FunctionComponent<contentInterface> = ({ type }) => {
  const state = useSelector((state: { folders: FolderState }) => state.folders);
  const uploadedFonts = useSelector(({ create }: any) => create.uploadedFonts);
  const productId = useSelector(({ roles }: any) => roles.selectedProductId);
  const productList = useSelector(({ roles }: any) => roles.navProductList);
  const { userData } = useSelector(
    (state: { authentication: AuthState }) => state.authentication
  );
  const { projectsForReadyCheck } = useSelector(
    (state: { hyperProject: HyperProjectFoldersState }) => state.hyperProject
  );
  const { userPermissions, selectedProductId } = useSelector(
    (state: { roles: RolesState }) => state.roles
  );
  const {
    folders,
    activeFolderId,
    selectedFolders,
    banners,
    pageData,
    uploadFileList,
    uploadedFolder,
    folderLoading,
    projectLoading,
    filter,
    uploadedBanners,
    folderBreadcrumb,
    selectedProjects,
    folderContentCount,
    tempFolderContentCount,
  } = state;
  const [drawerVisible, setDrawerVisible] = useState("");
  const folderFilter: Function = useCallback(
    (id: number) =>
      folders.filter((folder) => folder.parent === id && folder.type === type),
    [folders]
  );
  let filteredFolders: Folder[] = folderFilter(activeFolderId);
  const activeFolderFind: Function = useCallback(
    (id: number) =>
      folders.find((folder) => folder.id === id && folder.type === type),
    [folders]
  );
  let activeFolder: Folder = activeFolderFind(activeFolderId);
  const [openUploadDesignsPopUp, setOpenUploadDesignsPopUp] = useState(false);
  const getActiveFolder = useCallback(
    (id: number, type: string) =>
      folders.find((folder) => folder.id === id && folder.type === type) ||
      null,
    [folders]
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [publicLinkDrawerProps, setPublicLinkDrawerProps] = useState<any>(null);
  const [viewDetailsDrawerProps, setViewDetailsDrawerProps] = useState<any>(
    null
  );
  const isEditEnabled: boolean = enableEdit.some((val: string) =>
    userData?.emailId.includes(val)
  );

  useEffect(() => {
    const urlSearchParam = new URLSearchParams(getUrlProperties().search);
    const folderIds =
      urlSearchParam
        .get("folder")
        ?.split("-")
        .map((x) => +x) || [];
    if (type === "file") {
      dispatch(setFolderLoader(true));
      if (folderIds.length > 0) {
        dispatch(
          fetchFolders(
            { type, parent: [0, ...folderIds] },
            (res: any, err: boolean) => {
              dispatch(setFolderLoader(false));
              const breadcrumbFolders: any[] = [];
              if (!err) {
                res.results.forEach((folder: Folder) => {
                  if (folderIds.includes(folder.id)) {
                    breadcrumbFolders.push(folder);
                  }
                });
                dispatch(setFolderBreadcrumb(breadcrumbFolders));
                dispatch(addFolder(res.results));
              }
            }
          )
        );
        dispatch(setActiveFolder(folderIds[folderIds.length - 1]));
      } else {
        dispatch(
          fetchFolders({ type }, (res: any, err: boolean) => {
            dispatch(setFolderLoader(false));
            if (!err) {
              dispatch(addFolder(res.results));
            }
          })
        );
      }
    }
    return () => {
      dispatch(setFolders([]));
      dispatch(setSelectedProject([]));
      dispatch(setSelectedFolders([]));
      dispatch(setProjects([]));
      dispatch(setActiveFolder(-1));
      dispatch(setFoldersPageData(null));
    };
  }, []);

  useEffect(() => {
    if (type === "file" && activeFolderId >= 0) {
      dispatch(setProjectLoader(true));
      dispatch(
        fetchFiles(
          { page: 1, folder: activeFolderId, page_size: 20 },
          (res: any, err: boolean) => {
            dispatch(setProjectLoader(false));
            if (!err) {
              dispatch(
                setFoldersPageData({
                  count: res.count,
                  current_page: res.current_page,
                  page_size: res.page_size,
                  pages: res.pages,
                })
              );
              dispatch(setProjects(res.results));
            }
          }
        )
      );
    }
    return () => {
      dispatch(setProjects([]));
    };
  }, [activeFolderId]);

  const handleEmptyFileClick = (type: string) => {
    if (type === "project") {
      dispatch(setActiveFolderCreate({ folder: activeFolderId }));
      history.push("/cocreate/create");
    } else if (type === "file") {
      setDrawerVisible("uploadFile");
    } else if (type === "hpproject") {
      history.push("/cocreate/creative-automation-choose-design");
    }
  };

  // useEffect(()=>{
  //     if(selectedFolders.length > 0){
  //         window.addEventListener('keydown',listner);
  //     }
  //     return ()=>{
  //         window.removeEventListener('keydown',listner);
  //     }
  // },[selectedFolders])

  const updateData = (res: any, file: any, index: number, err?: boolean) => {
    if (type !== "project") return;
    if (res === "cancelled") return;
    if (!err) {
      let temp: any[] = store.getState().folders.uploadedBanners;
      let activeFolderId: any = store.getState().folders.activeFolderId;
      let project: any = omit(res.data, "project_json");
      project["parent"] = file.parent
        ? typeof file.parent === "object"
          ? file.parent.id
          : file.parent
        : null;
      project["status"] = "COMPLETED";
      project["can_manage"] = true;
      project["rendered_file"] =
        IMAGE_BASE_URL +
        project.rendered_file +
        (project.file_type !== "gif"
          ? detectQueryString(project.rendered_file)
            ? "&q=40"
            : "?q=40"
          : "");
      project["thumbnail"] = file.thumbUrl;
      temp[index] = project;
      console.log("project123123213", project);
      dispatch(setUploadedBanners([...temp]));

      if (
        activeFolderId === project.parent ||
        (activeFolderId === 0 && !project.parent)
      ) {
        dispatch(setProjects([project, ...store.getState().folders.banners]));
      }
    } else {
      let temp: any[] = store.getState().folders.uploadedBanners;
      temp[index] = {
        title: file.name,
        rendered_file: file.thumbUrl,
        thumbnail: file.thumbUrl,
        progress: 100,
        // 'file?.token?.token?.reason?.messag' will be 'Upload cancelled!'(set from uploadDesignPopup component)
        reason:
          file?.token?.token?.reason?.message !== undefined
            ? file?.token?.token?.reason?.message
            : res?.data?.message
            ? res?.data?.message
            : "Unable to Upload!",
        status: "FAILED",
        parent: file.parent ? file.parent : null,
      };
      // If close is not cliked from uploadDesignPopUp
      // if(uploadedBanners.length>0){
      dispatch(setUploadedBanners([...temp]));
      // openToast('error',res?.data?.message || "Unable to Upload!")
      // }
    }
  };

  const getConfig = (file: any, index: number) => {
    let loadedPart = 0;
    return {
      onUploadProgress: function(progressEvent: any) {
        // callback && callback();
        let percentCompleted = 0;
        if (file.size === progressEvent.total) {
          percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        } else {
          loadedPart += progressEvent.loaded;
          percentCompleted = Math.round(loadedPart / file.size);
        }
        console.log("fileProgress", file, percentCompleted, progressEvent);
        let temp: any[] = store.getState().folders.uploadedBanners;
        let i: number =
          temp.length === 0 ? 0 : temp[index] ? index : temp.length;
        temp[i] = {
          title: file.name,
          rendered_file: file.thumbUrl,
          thumbnail: file.thumbUrl,
          progress: percentCompleted,
          type: file.name.includes(".psd") ? "psd" : file.type.split("/")[0],
          status: "INPROGRESS",
          parent: file.parent ? file.parent : null,
          token: file.token,
        };

        if (percentCompleted % 10 === 0)
          dispatch(setUploadedBanners([...temp]));
      },
    };
  };

  const handleUploadDesigns = (
    fileList: any[],
    rootFolders: any[],
    callback?: Function
  ) => {
    let files = uploadFileList.length > 0 ? uploadFileList : fileList;
    dispatch(
      setUploadedBanners([
        ...uploadedBanners,
        ...files.map((file: any): any => {
          // if(file.type && file.type === 'folder') return file;
          return {
            title: file.name,
            rendered_file: file.thumbUrl,
            thumbnail: file.thumbUrl,
            progress: 0,
            type: file.name.includes(".psd") ? "psd" : file.type.split("/")[0],
            status: "NOT_STARTED",
            parent: file.parent ? file.parent : null,
            token: file.token,
          };
        }),
      ])
    );
    const { lastName, firstName, emailId } = userData;
    const fileTypes = files.map((file: any) => {
      const splitName = file.name.split(".");
      if (splitName.length <= 1) return "unknown";
      return splitName.pop();
    });
    trackUploadFiles({
      name: `${firstName} ${lastName}`,
      email: emailId,
      workspace:
        productList.find((product: any) => productId === product._id).name ||
        "",
      numberOfFiles: files.length,
      fileTypes: Array.from(new Set(fileTypes)),
    });

    const getPromiseWrapperForPSD = (
      file: any,
      index: number,
      extension: string,
      fileUploaderRes: any,
      fileToProjectToken: any,
      callback?: Function
    ) => {
      const config = getConfig(file, index);
      const b = fileUploaderRes.file_path.split("/");
      b[b.length - 1] = encodeURIComponent(b[b.length - 1]);
      const postData = {
        fileUrl: S3_BASE_URL + b.join("/"),
        fileType: extension.toUpperCase(),
      };
      if (postData.fileType === "SVG") {
        getSvg1(postData.fileUrl).then(({ img }: any) => {
          const width = Math.floor(img.getAttribute("width"));
          const height = Math.floor(img.getAttribute("height"));
          getImage({
            exportedAsset: postData.fileUrl,
            x: 0,
            y: 0,
            width,
            height,
            subType: NODE_TYPE.IMAGE,
          }).then((svgObj: any) => {
            const folder = rootFolders?.length
              ? file.parent?.id || activeFolderId
              : activeFolderId !== 0
              ? activeFolderId
              : null;
            const postData1 = {
              title: file.name,
              type: "image",
              product_id: selectedProductId,
              file_type: extension,
              rendered_file: fileUploaderRes.file_path,
              folder: folder || null,
              sizes: [
                {
                  type: "custom",
                  name: "custom1",
                  height,
                  width,
                },
              ],
              psdUrl: postData.fileUrl,
              cancelToken: fileToProjectToken,
            };
            dispatch(
              createProject(postData1, (res: any, err: boolean) => {
                if (!err) {
                  if (
                    svgObj.getSrc &&
                    (svgObj.getSrc().includes("data:") ||
                      svgObj.getSrc().includes("blob"))
                  ) {
                    const image = getSrc(svgObj);
                    getFile(image, svgObj.id).then((file: any) => {
                      const fd = new FormData();
                      fd.append("file", file);
                      fd.append("type", "asset");
                      fd.append("product_id", selectedProductId);
                      fd.append("project", res.data.id);
                      dispatch(
                        uploadFile(
                          fd,
                          (resp: any, isError: any) => {
                            if (!isError) {
                              // const b = resp.data.data.file_url.split("/");
                              // b[b.length - 1] = encodeURIComponent(
                              //   b[b.length - 1]
                              // );
                              const postData2 = {
                                product_id: selectedProductId,
                                project_json: {
                                  set: [],
                                  default: [],
                                  original: {
                                    dpi: 96,
                                    key: "original",
                                    children: [
                                      {
                                        ...svgObj.toObject(customizedKeysArr),
                                        src: `${IMAGE_BASE_URL}${resp.data.data.file_url}`,
                                      },
                                    ],
                                  },
                                },
                              };
                              dispatch(
                                updateProject(
                                  { projectId: res.data.id, data: postData2 },
                                  (res2: any, err2: any) => {
                                    if (!err2) {
                                      res.data.render_status = "NOT_STARTED";
                                      incrementFileUploaded();
                                      callback &&
                                        callback(false, {
                                          res,
                                          file,
                                          index,
                                          error: false,
                                        });
                                    } else {
                                      openToast(
                                        "error",
                                        "Updating project failed"
                                      );
                                    }
                                  }
                                )
                              );
                            }
                          },
                          {}
                        )
                      );
                    });
                  }
                } else {
                  callback && callback(true, { res, file, index, error: true });
                }
              })
            );
          });
        });
      } else {
        dispatch(
          getPSDJSON(
            { data: postData, fileToProjectToken },
            (psdJsonRes: any, err: boolean) => {
              console.log("psdJsonRes", psdJsonRes);
              // if() {
              let psdJson = psdJsonRes?.data || {
                width: 0,
                height: 0,
                set: [
                  {
                    name: "default",
                    size: {
                      width: 0,
                      height: 0,
                    },
                  },
                ],
                psdUrl: postData.fileUrl,
                fileUrl: "",
                psdId: "",
                psdName: "",
                children: [],
              };
              // if (extension !== "psd") {
              //   delete psdJson.psdUrl;
              // }
              const folder = rootFolders?.length
                ? file.parent?.id || activeFolderId
                : activeFolderId !== 0
                ? activeFolderId
                : null;
              const postData1 = {
                title: file.name,
                type: "image",
                product_id: selectedProductId,
                file_type: extension,
                rendered_file: fileUploaderRes.file_path,
                folder: folder || null,
                sizes:
                  extension === "psd"
                    ? [
                        {
                          type: "custom",
                          name: "custom1",
                          height: psdJson.height,
                          width: psdJson.width,
                        },
                      ]
                    : [
                        {
                          type: "custom",
                          name: "custom1",
                          height: psdJson.set[0].size.height,
                          width: psdJson.set[0].size.width,
                        },
                      ],
                psdUrl: psdJson.psdUrl || postData.fileUrl,
                cancelToken: fileToProjectToken,
              };
              dispatch(
                createProject(postData1, (res: any, err: boolean) => {
                  if (!err) {
                    const fontsArr = uploadedFonts.map(
                      (o: any) =>
                        `${o.font_family.replace(
                          / /g,
                          ""
                        )}-${o.font_style.replace(/ /g, "")}`
                    );
                    const layerObjects = psdJson.children
                      .reverse()
                      .map((obj: any) => {
                        if (
                          uploadedFonts &&
                          obj.font &&
                          fontsArr.includes(obj.font.replace(/ /g, ""))
                        ) {
                          obj.isCustomFont = true;
                          obj.productId = productId;
                        }
                        return { ...obj, dontShowToast: true };
                      });
                    Promise.all(layerObjects.map(getObject)).then(
                      (objects: any) => {
                        const postData2 = {
                          product_id: selectedProductId,
                          project_json: {
                            set: [],
                            default: [],
                            original: {
                              dpi: 96,
                              key: "original",
                              children: objects
                                .filter((obj: any) => obj !== undefined)
                                .map((o: any) => o.toObject(customizedKeysArr)),
                            },
                          },
                        };
                        dispatch(
                          updateProject(
                            { projectId: res.data.id, data: postData2 },
                            (res2: any, err2: any) => {
                              if (!err2) {
                                res.data.render_status = "INPROGRESS";
                                incrementFileUploaded();
                                callback &&
                                  callback(false, {
                                    res,
                                    file,
                                    index,
                                    error: false,
                                  });
                              } else {
                                openToast("error", "Updating project failed");
                              }
                            }
                          )
                        );
                      }
                    );
                  } else {
                    callback &&
                      callback(true, { res, file, index, error: true });
                  }
                })
              );
              // }
              // else {
              //     if(uploadedBanners.length>0)
              //     openToast('error', 'Failed to get PSD JSON');
              //     callback && callback(true,{psdJsonRes,file,index,error:true});
              // }
            },
            config
          )
        );
      }
    };
    const getPromiseWrapperForOtherFiles = (
      file: any,
      index: number,
      extension: string,
      fileUploaderRes: any,
      fileToProjectToken: any,
      callback?: Function
    ) => {
      dispatch(
        projectUpload(
          {
            title: file.name,
            type: getFileType(file.type),
            file_type: extension,
            folder: file.parent?.id || activeFolderId,
            rendered_file: fileUploaderRes.file_path,
            cancelToken: fileToProjectToken,
          },
          (res: any, err: boolean) => {
            if (!err) {
              incrementFileUploaded();
              callback && callback(false, { res, file, index, error: false });
            } else {
              callback && callback(true, { res, file, index, error: true });
            }
          }
        )
      );
    };
    throttleRequests(files, rootFolders, {
      psd: getPromiseWrapperForPSD,
      other: getPromiseWrapperForOtherFiles,
    });
    callback && callback();
  };

  const throttleRequests = (
    files: any[],
    rootFolders: any[],
    promiseWrapper: { psd: Function; other: Function },
    maxParallelRequests = 6
  ) => {
    var q = async.queue(function(task: any, callback: any) {
      task(callback);
    }, 10);
    dispatch(setFileToProjectQueue(q));
    let error: boolean = false;
    files.forEach((file: any, index: number) => {
      q.push(
        (callback: any) => {
          // There will be 2 scenario
          // 1. API is called when user cancel file
          // 2. API is not called when user cancel file(It's only in upload list)

          // We will not call API if file is cancelled by user(It's only in upload list)
          if (file.status !== "CANCELLED") {
            const config: any = getConfig(file, index);

            // Token to cancel API once it is called
            let fileToProjectToken = axios.CancelToken.source();
            file.token = fileToProjectToken;

            // Give unique name to each file so it's not replaced by other file while we upload file to S3
            let extension: string = file.name.split(".").pop();
            let file_name =
              file.name.slice(0, file.name.lastIndexOf(extension) - 1) +
              v4() +
              "." +
              extension;
            const file_size = file.size;
            // Upadting cancel token as soon as API is called
            let temp: any[] = store.getState().folders.uploadedBanners;
            let i: number =
              temp.length === 0 ? 0 : temp[index] ? index : temp.length;
            temp[i] = {
              title: file.name,
              rendered_file: file.thumbUrl,
              thumbnail: file.thumbUrl,
              progress: 0,
              type: file.name.includes(".psd")
                ? "psd"
                : file.type.split("/")[0],
              status: "INPROGRESS",
              parent: file.parent ? file.parent : null,
              token: file.token,
            };
            dispatch(setUploadedBanners([...temp]));
            dispatch(
              fileUploader(
                {
                  file_name,
                  file_size,
                  file,
                  config,
                  cancelToken: fileToProjectToken,
                },
                (fileUploaderRes: any, error: boolean) => {
                  console.log("fileUploaderResindex", fileUploaderRes);
                  if (!error) {
                    if (fileUploaderRes?.location) {
                      promiseWrapper[
                        extension === "psd" || extension === "svg"
                          ? "psd"
                          : "other"
                      ](
                        file,
                        index,
                        extension,
                        fileUploaderRes,
                        fileToProjectToken,
                        callback
                      );
                    } else {
                      dispatch(
                        uploadToS3(
                          {
                            file_url: fileUploaderRes.uploadURL,
                            file,
                            cancelToken: fileToProjectToken,
                          },
                          (S3res: any, error: boolean) => {
                            console.log("S3res", S3res);
                            if (!error) {
                              promiseWrapper[
                                extension === "psd" || extension === "svg"
                                  ? "psd"
                                  : "other"
                              ](
                                file,
                                index,
                                extension,
                                fileUploaderRes,
                                fileToProjectToken,
                                callback
                              );
                            } else {
                              callback &&
                                callback(true, {
                                  S3res,
                                  file,
                                  index,
                                  error: true,
                                });
                            }
                          },
                          config
                        )
                      );
                    }
                  } else {
                    callback &&
                      callback(true, {
                        fileUploaderRes,
                        file,
                        index,
                        error: true,
                      });
                  }
                }
              )
            );
          } else {
            let response = "cancelled";
            callback &&
              callback(true, { res: response, file, index, error: true });
          }
        },
        function(err: any, data: any) {
          console.log("datacheck", data);
          error = data.error;
          updateData(data.res, data.file, data.index, data.error);
        }
      );
    });

    console.log("datacheck2", error);
    q.drain(function() {
      if (!error) {
        if (rootFolders?.length) {
          openToast(
            "success",
            `Folder${
              Object.keys(rootFolders).length > 1 ? "s" : ""
            } uploaded successfully`
          );
        } else {
          openToast(
            "success",
            `File${files.length > 1 ? "s" : ""} uploaded successfully`
          );
        }
      } else {
        openToast("error", "Upload failed");
      }
    });

    q.error(function(err: any, task: any) {
      //   console.error('task experienced an error',{err,task});
    });
  };

  return (
    <div className='dashboard-content' id='content'>
      {type === "project" && activeFolderId === 0 && <QuickViewAccess />}
      {!filter && (
        <Folders
          folders={filteredFolders}
          canCreate={userPermissions.indexOf("create-new-banners") > -1}
          type={type}
          activeFolder={activeFolder}
          setOpenUploadDesignsPopUp={setOpenUploadDesignsPopUp}
          componentCaller='folder'
          setPublicLinkDrawerProps={setPublicLinkDrawerProps}
          setViewDetailsDrawerProps={setViewDetailsDrawerProps}
          drawerVisible={drawerVisible}
          setDrawerVisible={setDrawerVisible}
          isEditEnabled={isEditEnabled}
        />
      )}
      <Banners
        type={type}
        setPublicLinkDrawerProps={setPublicLinkDrawerProps}
        setViewDetailsDrawerProps={setViewDetailsDrawerProps}
        title={type === "file" ? "Uploads" : "Banners"}
        showTitle={
          type === "file" || type === "hpproject" ? true : banners.length > 0
        }
        canUpload={userPermissions.indexOf("create-new-banners") > -1}
        loading={projectLoading}
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        projectsForReadyCheck={projectsForReadyCheck}
        getActiveFolder={getActiveFolder}
        componentCaller='project'
        isEditEnabled={isEditEnabled}
      />
      {((filteredFolders.length > 0 &&
        banners.length === 0 &&
        !folderLoading &&
        !projectLoading &&
        filter &&
        filter["search"]) ||
        (banners.length === 0 && !projectLoading && !folderLoading)) && (
        <EmptyFile
          title={
            type !== "file"
              ? filteredFolders.length > 0
                ? `Oops! Nothing found in current folder`
                : "Create your design"
              : "Upload files"
          }
          subtitle={`Oops, you have not added any ${
            type !== "file" ? " designs " : " files "
          } here!`}
          isDisabled={type !== "file" ? !isEditEnabled : false}
          btnTxt={
            type !== "file" ? (
              <span
                className={
                  userPermissions.indexOf("create-new-banners") > -1
                    ? "txt-container"
                    : "txt-container permission-denied-lock"
                }
              >
                <Icon className='icon' component={art} />
                Create a new design
              </span>
            ) : (
              <span
                className={
                  userPermissions.indexOf("create-new-banners") > -1
                    ? "txt-container"
                    : "txt-container permission-denied-lock"
                }
              >
                <Icon component={iconUpload} className='icon' />
                Upload files
              </span>
            )
          }
          handleClick={() => {
            if (userPermissions.indexOf("create-new-banners") === -1) {
              permissionDeniedClick();
            } else {
              handleEmptyFileClick(type);
            }
          }}
          className='project'
          isPrimaryBtn={type === "project" ? isEditEnabled : true}
          isSecondaryBtn={type === "project"}
          secondaryBtnTxt={
            <span
              className={
                userPermissions.indexOf("create-new-banners") > -1
                  ? "txt-container"
                  : "txt-container permission-denied-lock"
              }
            >
              <Icon component={uploadDesign} className='icon' />
              Upload designs
            </span>
          }
          handleSecondaryBtnClick={() => {
            if (userPermissions.indexOf("create-new-banners") === -1) {
              permissionDeniedClick();
            } else {
              setOpenUploadDesignsPopUp(true);
              dispatch(setUploadedBanners([]));
              dispatch(setUploadFileList([]));
            }
          }}
          isToolTip={type !== "file" ? !isEditEnabled : false}
          toolTipText={
            "Get in touch with our customer success manager to know more about our editing functionality."
          }
        />
      )}
      {openUploadDesignsPopUp && (
        <UploadDesignsDrawer
          onSubmit={handleUploadDesigns}
          handleClose={() => setOpenUploadDesignsPopUp(false)}
        />
      )}
      {publicLinkDrawerProps && (
        <PublicLinkDrawer
          {...publicLinkDrawerProps}
          handleClose={() => setPublicLinkDrawerProps(null)}
        />
      )}
      {viewDetailsDrawerProps && (
        <ViewDetailsDrawer
          {...viewDetailsDrawerProps}
          handleClose={() => setViewDetailsDrawerProps(null)}
        />
      )}
    </div>
  );
};

export default Content;

export { Banners, Folders };
