import React, { useEffect } from "react";
import FileIcon from "../../assets/img/VLEFile.png";
import "../VLEStatus/VLEStatus.scss";
import { Button } from "pyxis-ui-kit";
import { Table } from "antd";
import httpService from "../../HttpService/HttpService";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { VLEProductId } from "../VLEStatus/index";
import { useHistory } from "react-router-dom";
const convertTableData = (data) => {
  return data.map((item, index) => {
    const options = { timeZone: "UTC", hour12: false };
    if (item.submit_proof_date != null) {
      var submit_proof_date = new Date(item.submit_proof_date)
        .toLocaleString()
        .replace(",", "");
    } else {
      submit_proof_date = "null";
    }
    return {
      key: index,
      vle_id: item.vle_id,
      expiration_date: new Date(item.expiration_date)
        .toLocaleString("en-GB", options)
        .replace(",", ""),
      // expiration_date: item.expiration_date,
      submit_proof_flag: item.submit_proof_flag,
      submit_proof_date: submit_proof_date,
      outside_image_proof_url: String(item.outside_image_proof_url).replace(
        /"/g,
        ""
      ),
      inside_image_proof_url: String(item.inside_image_proof_url).replace(
        /"/g,
        ""
      ),
      project_link: String(item.project_link).replace(/"/g, ""),
      folder_name: String(item.folder_name).replace(/"/g, ""),
      url_hash: item.url_hash,
    };
  });
};

const columns = [
  {
    title: "IDs",
    dataIndex: "vle_id",
    key: "vle_id",
  },
  {
    title: "Url hash",
    dataIndex: "url_hash",
    key: "url_hash",
  },
  {
    title: "Project link",
    dataIndex: "project_link",
    key: "project_link",
    ellipsis: true,
  },
  {
    title: "Expiration date",
    dataIndex: "expiration_date",
    key: "expiration_date",
  },
  {
    title: "Folder name",
    dataIndex: "folder_name",
    key: "folder_name",
  },
  {
    title: "Inside image proof",
    dataIndex: "inside_image_proof_url",
    key: "inside_image_proof_url",
    ellipsis: true,
  },
  {
    title: "Outside image proof",
    dataIndex: "outside_image_proof_url",
    key: "outside_image_proof_url",
    ellipsis: true,
  },
  {
    title: "Submit proof date",
    dataIndex: "submit_proof_date",
    key: "submit_proof_date",
  },
];

export default function VLEStatus() {
  const getTokenOrPublic = (state: any) =>
    state.authentication.token || state.publicLink.token;
  const state = useSelector((state: any) => state);
  const [tableData, setTableData] = React.useState([]);
  const selectedProductId = useSelector(
    (state: { roles: any }) => state.roles.selectedProductId
  );
  const history = useHistory();

  const params: any = useParams();

  useEffect(() => {
    getFolderDetail(params.url_hash);
    if (selectedProductId != VLEProductId) {
      history.push("/");
    }
  }, [selectedProductId]);

  const getFolderDetail = (folderId) => {
    httpService
      .get(
        process.env.REACT_APP_DESIGN_URI,
        `/api/vleDataBasedonFolder/?url_hash=${folderId}`,
        getTokenOrPublic(state)
      )
      .then((res) => {
        setTableData(res.data.data.result);
      });
  };

  const handleBack = () => {
    window.history.back();
  };

  function tableToCSV() {
    const _tableData = convertTableData(tableData);
    _tableData.forEach((item, index) => {
      delete item.key;
      delete item.submit_proof_flag;
    });

    //@ts-ignore
    const header = Object.keys(_tableData[0]).join(",");
    const rows = _tableData.map((item) =>
      Object.values(item)
        .map((value) => (value !== null ? `"${value}"` : ""))
        .join(",")
    );
    const csvContent = [header, ...rows].join("\n");

    return csvContent;
  }

  function downloadCSVFile(csv_data, folderName) {
    let CSVFile;
    CSVFile = new Blob([csv_data], { type: "text/csv" });

    let temp_link = document.createElement("a");
    const fileName = `${folderName}.csv`;

    temp_link.download = fileName;
    let url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    temp_link.click();
    document.body.removeChild(temp_link);
  }

  const handleDownloadCSV = () => {
    downloadCSVFile(tableToCSV(), params.folder_name);
  };

  return (
    <div className='profile-wrapper'>
      <div className='VLE-status-container'>
        <div className='header' onClick={handleBack}>
          <span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                d='M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z'
                fill='#18236C'
              />
            </svg>
          </span>
          <span className='header-title'>{params.folder_name}</span>
        </div>
        <div className='sub-header'>
          <Button className='download-csv-btn' onClick={handleDownloadCSV}>
            Download CSV
          </Button>
        </div>

        <div>
          <Table dataSource={convertTableData(tableData)} columns={columns} />
        </div>
      </div>
    </div>
  );
}
